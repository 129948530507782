import React, { FC, MouseEventHandler, ReactNode } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import cn from 'classnames';
import st from './Button.module.scss';

interface Props {
    children: ReactNode;
    variant?: 'green' | 'red' | 'gray';
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    className?: string;
    loading?: boolean;
    buttonChildrenClassName?: string;
    disableTooltip?: string;
}

export const Button: FC<Props & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    children,
    variant = 'green',
    className,
    onClick,
    disabled,
    loading,
    buttonChildrenClassName,
    disableTooltip,
    ...props
}) => {
    const handleClick = !disabled && !loading ? onClick : undefined;
    if (disabled && disableTooltip) {
        return (
            <Tooltip title={disableTooltip} placement="top">
                <div>
                    <button
                        onClick={handleClick}
                        className={cn(
                            st.button,
                            st[variant],
                            {
                                [st.disabled]: disabled,
                                [st.loading]: loading
                            },
                            className
                        )}
                        {...props}>
                        <div className={cn(st.buttonChildren, buttonChildrenClassName)}>
                            {children}
                        </div>
                        <div className={st.loader}>
                            <CircularProgress size={20} />
                        </div>
                    </button>
                </div>
            </Tooltip>
        );
    }

    return (
        <button
            onClick={handleClick}
            className={cn(
                st.button,
                st[variant],
                {
                    [st.disabled]: disabled,
                    [st.loading]: loading
                },
                className
            )}
            {...props}>
            <div className={cn(st.buttonChildren, buttonChildrenClassName)}>{children}</div>
            <div className={st.loader}>
                <CircularProgress size={20} />
            </div>
        </button>
    );
};
