import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { closedRouters, routers } from 'constants/routes';
import 'assets/styles/styles';
import { Header } from 'components/Layout/Header/Header';
import { useUserData } from 'context/hooks/useUserData';
import { PATHS, PATHS_WITHOUT_HEADER } from 'constants/paths';
import { useUserDto } from 'api/hooks/useUser';
import { useTabletL } from 'utils/windowSize';
import { MobileHeader } from 'components/Layout/Header/MobileHeader';
import { useSetSize } from 'hooks/useSetSize';
import { useAccount } from 'wagmi';
import { COMING_SOON_DATE, IS_STAGING, WEB_CLOSED } from 'constants/common';
import { ConfirmModal } from 'components/shared/modal/ConfirmModal';
import { useConfirmModal } from 'context/hooks/useConfirmModal';
import { useLogout } from 'hooks/useLogout';

function App() {
    useSetSize();
    const { modal } = useConfirmModal();
    const {
        userData: { token, wallet }
    } = useUserData();
    const { address, connector } = useAccount();
    const { logout } = useLogout();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useUserDto();
    const isTabletL = useTabletL();

    const isAuth = !!token && !!wallet;
    const comingSoon = !IS_STAGING && COMING_SOON_DATE && new Date(COMING_SOON_DATE) > new Date();

    const currentRouters = WEB_CLOSED ? closedRouters : routers;

    const showHeader = isAuth && !WEB_CLOSED && PATHS_WITHOUT_HEADER.indexOf(pathname) === -1;

    useEffect(() => {
        if ((!address && token) || (comingSoon && token) || (token && !connector)) {
            logout();
            if (!PATHS_WITHOUT_HEADER.includes(pathname)) {
                navigate(PATHS.LOGIN);
            }
        }
    }, []);

    useEffect(() => {
        if (!isAuth) {
            logout();
        }
    }, [isAuth]);

    return (
        <div className="app">
            {showHeader && (isTabletL ? <Header /> : <MobileHeader />)}
            <ConfirmModal {...modal} />
            <Routes>
                {currentRouters.map(({ path, element }) => (
                    <Route key={path} path={path} element={element} />
                ))}
                <Route
                    path="*"
                    element={<Navigate to={isAuth ? PATHS.DASHBOARD : PATHS.LOGIN} replace />}
                />
            </Routes>
        </div>
    );
}

export default App;
