import { apiClient } from 'api/apiClient';
import { withDefaultHandlers } from 'api/helpers/withDefaultHandlers';
import { BalancesDto } from 'types/balance';
import { ResponseDto } from 'types/common';
import { WalletDto, WalletNetworkNames, WalletsTypes } from 'types/wallet';

const BASE_PATH = '/api/user/wallet';

export const apiGetBalances = () =>
    withDefaultHandlers(apiClient.get<ResponseDto<BalancesDto>>('/api/balance'));

export const apiPostWallet = (body: {
    wallet: string;
    net: WalletNetworkNames;
    signature: string;
    app: WalletsTypes;
}) => withDefaultHandlers(apiClient.post<ResponseDto<WalletDto[]>>(`${BASE_PATH}/add`, body));

export const apiDeleteWallet = (wallet: string) =>
    withDefaultHandlers(
        apiClient.post<ResponseDto<WalletDto[]>>(`${BASE_PATH}/delete`, { wallet })
    );
